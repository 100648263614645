<template>
  <main class="search">
    <h1>Search</h1>
    <section class="search">
      <div class="search-input">
        <i class="fa-solid fa-search fa-lg"></i>
        <input type="text" v-model="searchInput" placeholder="What are you looking for?">
      </div>
      <button>Search</button>
    </section>
  </main>
</template>

<script>
export default {
  data: () => ({
    searchInput: "",
  }),
};
</script>

<style>
</style>